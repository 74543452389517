import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import FavoriteButton from './FavoriteButton';
import { domain } from '../../App';
import AverageRating from '../AverageRating';

export default function ActivityCard({ activity, favoritesId, assign, assignToPatient }) {
  const navigate = useNavigate();
  const tags = activity?.ocgTTagsByActivityId?.nodes;

  function editActivity() {
    navigate(`/activities/${activity.id}`);
  }

  return (
    <Card sx={{ display:'flex', marginTop:'1rem', height:'170px', cursor:'pointer' }} onClick={editActivity}>
      <CardMedia component="img" sx={{ width:151 }} image={`${domain}/files/activities/${activity.id}/${activity.imageFileName}`} alt={`${activity.name} image`} />
      <Box sx={{ display:'flex', flexDirection:'column', overflow:'hidden', flex:1 }}>
        <CardContent sx={{ flex:'1 0 auto' }}>
          <Typography component="div" variant="h5">{activity.name}</Typography>
          <Typography variant="description" color="text.secondary" component="div">{activity.description}</Typography>
        </CardContent>
        <Box sx={{ display:'flex', alignItems:'center', pl:1, pb:1 }}>
          {tags?.map(tag => <Chip key={tag.id} label={tag.name} size="small" sx={{ ml:'0.5rem', borderRadius:'4px' }} />)}
        </Box>
      </Box>
      <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-end', p:1, minWidth:140 }} onClick={e => e.stopPropagation()}>
        <Box sx={{ display:'flex', alignItems:'center' }}>
          <AverageRating activityId={activity.id} showText={false} />
          <Chip label={activity.activityType} size="small" variant="outlined" sx={{ ml:1, borderRadius:'4px' }} />
        </Box>
        {!assign
          ? <FavoriteButton activityId={activity.id} favoritesId={favoritesId} sx={{ mt:'auto' }} />
          : <Button variant="contained" size="small" disableElevation onClick={e => { e.stopPropagation(); assignToPatient(); }} sx={{ height:'30px', mt:'auto' }}>
              Assign
            </Button>
        }
      </Box>
    </Card>
  );
}
