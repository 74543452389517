import * as React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  Button,
  Typography,
  Rating,
  TextField,
  Box,
  Collapse,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation, gql } from '@apollo/client';
import db from '../scripts/db';

// GraphQL query using camelCase for parent comment field
const ALL_COMMENTS = gql`
  query AllComments($activityId: UUID!) {
    allOcgTComments(
      condition: { activityId: $activityId, parentCommentId: null }
      orderBy: CREATED_AT_ASC
    ) {
      nodes {
        id
        clinicianId
        author
        rating
        text
        createdAt
        parentCommentId
        ocgTCommentsByParentCommentId(orderBy: CREATED_AT_ASC) {
          nodes {
            id
            clinicianId
            author
            text
            createdAt
            parentCommentId
          }
        }
      }
    }
  }
`;

// Mutation for adding a new comment or reply
const ADD_COMMENT = gql`
  mutation AddComment(
    $activityId: UUID!
    $clinicianId: Int
    $author: String!
    $rating: Int!
    $text: String!
    $parentCommentId: Int
  ) {
    createOcgTComment(
      input: {
        ocgTComment: {
          activityId: $activityId
          clinicianId: $clinicianId
          author: $author
          rating: $rating
          text: $text
          parentCommentId: $parentCommentId
        }
      }
    ) {
      ocgTComment {
        id
      }
    }
  }
`;

// Mutation for updating an existing comment
const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: Int!, $text: String!, $rating: Int) {
    updateOcgTCommentById(
      input: { id: $id, ocgTCommentPatch: { text: $text, rating: $rating } }
    ) {
      ocgTComment {
        id
        text
        rating
      }
    }
  }
`;

// Mutation for deleting a comment
const DELETE_COMMENT = gql`
  mutation DeleteComment($id: Int!) {
    deleteOcgTCommentById(input: { id: $id }) {
      ocgTComment {
        id
      }
    }
  }
`;

export default function CommentSection({ activityId }) {
  const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
  const clinicianId = clinician?.id;
  const role = clinician?.role; // clinicians can edit their own; editors can edit any

  const { data, loading, error, refetch } = useQuery(ALL_COMMENTS, {
    variables: { activityId },
    fetchPolicy: 'network-only'
  });

  const [addComment] = useMutation(ADD_COMMENT);
  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  const [newComment, setNewComment] = React.useState({ rating: 0, text: '' });
  const [replyTexts, setReplyTexts] = React.useState({});
  const [replyOpen, setReplyOpen] = React.useState({});
  const [showCommentInput, setShowCommentInput] = React.useState(false);

  // For editing (only one comment/reply at a time)
  const [editingCommentId, setEditingCommentId] = React.useState(null);
  const [editingText, setEditingText] = React.useState('');
  const [editingRating, setEditingRating] = React.useState(0);

  if (loading)
    return (
      <Box sx={{ mt: 4, maxWidth: '800px', height: '100%' }}>
        <Typography>Loading comments...</Typography>
      </Box>
    );
  if (error)
    return (
      <Box sx={{ mt: 4, maxWidth: '800px', height: '100%' }}>
        <Typography>Error loading comments.</Typography>
      </Box>
    );

  const comments = data?.allOcgTComments?.nodes || [];

  // Handler for adding a new top-level comment
  const handleNewCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.text || newComment.rating === 0) return;
    try {
      await addComment({
        variables: {
          activityId,
          clinicianId,
          author: clinician?.name || 'Anonymous',
          rating: newComment.rating,
          text: newComment.text,
          parentCommentId: null
        }
      });
      setNewComment({ rating: 0, text: '' });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  // Toggle reply input visibility (only for top-level comments)
  const toggleReply = (commentId) => {
    setReplyOpen((prev) => ({ ...prev, [commentId]: !prev[commentId] }));
  };

  // Handler for adding a reply
  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    const replyText = replyTexts[commentId];
    if (!replyText) return;
    try {
      await addComment({
        variables: {
          activityId,
          clinicianId,
          author: clinician?.name || 'Anonymous',
          rating: 0, // Replies have rating 0.
          text: replyText,
          parentCommentId: commentId
        }
      });
      setReplyTexts({ ...replyTexts, [commentId]: '' });
      setReplyOpen({ ...replyOpen, [commentId]: false });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  // Start editing a comment or reply
  const startEditing = (comment) => {
    setEditingCommentId(comment.id);
    setEditingText(comment.text);
    // Only top-level comments allow rating edits
    setEditingRating(comment.parentCommentId === null ? comment.rating : 0);
  };

  // Cancel edit mode
  const cancelEditing = () => {
    setEditingCommentId(null);
    setEditingText('');
    setEditingRating(0);
  };

  // Handler for submitting an edit update
  const handleEditSubmit = async (e, comment) => {
    e.preventDefault();
    try {
      await updateComment({
        variables: {
          id: comment.id,
          text: editingText,
          rating: comment.parentCommentId === null ? editingRating : 0
        }
      });
      setEditingCommentId(null);
      setEditingText('');
      setEditingRating(0);
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  // Handler for deleting a comment or reply
  const handleDelete = async (commentId) => {
    try {
      await deleteComment({
        variables: { id: commentId }
      });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  // Render a single comment (or reply)
  const renderComment = (comment) => {
    // Allow editing/deleting if clinician is the author or if role is 'editor'
    const canEdit = role === 'editor' || comment.clinicianId === clinicianId;
    return (
      <Box
        key={comment.id}
        sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', '&:last-child': { mb: 0 } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            {comment.author}
            {comment.parentCommentId === null && (
              <>
                &nbsp;
                <Rating value={comment.rating} readOnly size="small" />
              </>
            )}
          </Typography>
          {canEdit && editingCommentId !== comment.id && (
            <Box>
              <IconButton onClick={() => startEditing(comment)} size="small" sx={{ ml: 0.5 }}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDelete(comment.id)} size="small" color="error" sx={{ ml: 0.5 }}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        {editingCommentId === comment.id ? (
          <Box component="form" onSubmit={(e) => handleEditSubmit(e, comment)} sx={{ mt: 1 }}>
            <TextField
              label="Edit comment..."
              multiline
              fullWidth
              rows={3}
              value={editingText}
              onChange={(e) => setEditingText(e.target.value)}
              sx={{ mt: 1 }}
            />
            {comment.parentCommentId === null && (
              <Rating
                name="edit-comment-rating"
                value={editingRating}
                onChange={(event, newValue) => setEditingRating(newValue)}
                sx={{ mt: 1 }}
              />
            )}
            <Box sx={{ mt: 1 }}>
              <Button variant="contained" type="submit" size="small" sx={{ mr: 1 }}>
                Save
              </Button>
              <Button variant="outlined" onClick={cancelEditing} size="small">
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {comment.text}
          </Typography>
        )}
        {/* Only top-level comments get a Reply button */}
        {comment.parentCommentId === null && (
          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
            <Button size="small" onClick={() => toggleReply(comment.id)}>
              Reply to this string
            </Button>
          </Box>
        )}
        {replyOpen[comment.id] && (
          <Box component="form" onSubmit={(e) => handleReplySubmit(e, comment.id)} sx={{ mt: 1 }}>
            <TextField
              label="Write a reply..."
              multiline
              fullWidth
              rows={2}
              value={replyTexts[comment.id] || ''}
              onChange={(e) =>
                setReplyTexts({ ...replyTexts, [comment.id]: e.target.value })
              }
            />
            <Button variant="contained" size="small" type="submit" sx={{ mt: 1 }}>
              Post Reply
            </Button>
          </Box>
        )}
        {/* Render replies */}
        {comment.ocgTCommentsByParentCommentId?.nodes.map((reply) =>
          renderComment(reply)
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '800px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Header & Collapsible Comment Form */}
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" gutterBottom>
            Comments / Questions
          </Typography>
          <Button
            variant="text"
            onClick={() => setShowCommentInput(!showCommentInput)}
            endIcon={
              <ExpandMoreIcon
                sx={{
                  transform: showCommentInput ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              />
            }
          >
            {showCommentInput ? 'Hide Comment Form' : 'Add New Comment'}
          </Button>
        </Box>
        <Collapse in={showCommentInput}>
          <Box component="form" onSubmit={handleNewCommentSubmit} sx={{ mb: 3 }}>
            <Rating
              name="new-comment-rating"
              value={newComment.rating}
              onChange={(event, newValue) => setNewComment({ ...newComment, rating: newValue })}
            />
            <TextField
              label="Write your comment..."
              multiline
              fullWidth
              rows={3}
              value={newComment.text}
              onChange={(e) => setNewComment({ ...newComment, text: e.target.value })}
              sx={{ mt: 1 }}
            />
            <Button variant="contained" type="submit" sx={{ mt: 1 }}>
              Post Comment
            </Button>
          </Box>
        </Collapse>
      </Box>
      {/* Scrollable Comment List */}
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {comments.map((comment) => renderComment(comment))}
      </Box>
    </Box>
  );
}
