import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery, gql } from '@apollo/client';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import Link from '../Link';
import FavoriteButton from './FavoriteButton';
import { domain } from '../../App';
import db from '../../scripts/db';
import { breadcrumbStyles } from '../../scripts/styles';
import SelectPatientDialog from '../Patients/SelectPatientDialog';
import PreviewActivity from './PreviewActivity';
import CopyActivityDialog from './CopyActivityDialog';
import CommentSection from '../CommentSection';
import AverageRating from '../AverageRating';

const gridStyles = {
    maxWidth: '1000px',
    display: 'grid',  
    gridTemplateColumns: '250px 1fr',
    gap: '2rem',
    alignItems: 'start',
    height: 'calc(100vh - 128px)'
};
const bigButtonStyles = {
    width: 'calc(100% - 1rem)',
    margin: '0.25rem 0.5rem',
    fontWeight: 'bold'
}
const thirdButtonRowStyles = {
    ...bigButtonStyles,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}
const GET_ACTIVITY = gql`
    query GetActivity($id: UUID!, $clinicianId: Int!) {
        ocgTActivityById(id: $id) {
            id
            activityId
            name
            description
            activityType
            imageFileName
            published
            steps
            clinicianId
            ocgTFavoritesByActivityId(condition: {clinicianId: $clinicianId}) { nodes { id }}
            ocgTTagsByActivityId(orderBy: NAME_ASC) { nodes { id name }}
            ocgTClinicianByClinicianId { name }
        }
    }
`;
export default function ActivityDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [openPatientDialog, setOpenPatientDialog] = React.useState(false);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const clinicianId = clinician?.id;
    const role = clinician?.role;
    const getActivity = useQuery(GET_ACTIVITY, {
        variables: { id: params.id, clinicianId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const activity = getActivity?.data?.ocgTActivityById;
    const tags = activity?.ocgTTagsByActivityId?.nodes;
    const tagNames = tags?.map(tag => tag.name).join(', ');
    const favoritesId = activity?.ocgTFavoritesByActivityId?.nodes?.[0]?.id;
    if (activity) db.appbar.put({ key: 1, title: activity.name });

    React.useEffect(() => {
        if (getActivity?.error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [getActivity, navigate]);
    if (!activity) return null;
    return (
        <React.Fragment>
            <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/activities">Activities</Link>
                <Typography color="text.primary">{activity.name}</Typography>
            </Breadcrumbs>

            <div style={gridStyles}>
                <div style={{display: 'grid', justifyItems: 'center'}}>
                    <img src={`${domain}/files/activities/${activity.id}/${activity.imageFileName}`} alt={`${activity.name}`} style={{ margin:'1rem 0', width: '240px', height:'240px'}}/>
                    <AverageRating activityId={activity.id} showText={true} />
                    <Button variant="contained" size="small" disableElevation style={bigButtonStyles} disabled={activity.clinicianId !== clinicianId && role !== 'editor'}
                        onClick={() => navigate(`/activities/steps/${activity.id}`)}>Get Started or<br /> Edit Activity</Button>
                    <Button variant="contained" size="small" disableElevation style={bigButtonStyles} onClick={() => setOpenPreviewDialog(true)}>Preview</Button>
                    <Button variant="contained" size="small" disableElevation style={bigButtonStyles} onClick={() => setOpenPatientDialog(true)}>Assign</Button>
                    <Button variant="contained" size="small" disableElevation style={bigButtonStyles} onClick={() => setOpenCopyDialog(true)}>Copy</Button>
                     <div style={thirdButtonRowStyles}>
                        <FavoriteButton activityId={activity.id} favoritesId={favoritesId} style={{margin:'none'}} />
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateRows: 'auto 1fr', margin: '0 1rem', height: 'calc(100vh - 128px)'}}>
                    <div>
                        <h2 style={{marginTop: '1rem'}}>{activity.name}</h2>
                        <p>{activity.description}</p>
                        <h5 style={{margin: '1.5rem 0 0.4rem 0'}}>Activity ID</h5>
                        <small>{activity.activityId}</small>
                        <h5 style={{margin: '1.5rem 0 0.4rem 0'}}>Activity Type</h5>
                        <small>{activity.activityType}</small>
                        <h5 style={{margin: '1.5rem 0 0.4rem 0'}}>Posted By</h5>
                        <small>{activity?.ocgTClinicianByClinicianId?.name}</small>
                        <h5 style={{margin: '1.5rem 0 0.4rem 0'}}>Search Terms</h5>
                        <small>{tagNames}</small>
                    </div>

                    <div style={{ margin: '1.5rem 0 0 0', flex: 1, overflow: 'auto' }}>
                        <CommentSection activityId={activity.id} />
                    </div>
                </div>
            </div>
            <SelectPatientDialog activityId={activity.id} clinicianId={clinicianId} openDialog={openPatientDialog} 
                setOpenDialog={setOpenPatientDialog} assignmentsCreated={(patientId) => navigate(`/patients/${patientId}`)} />
            <PreviewActivity activity={activity} openDialog={openPreviewDialog} setOpenDialog={setOpenPreviewDialog} />
            <CopyActivityDialog activity={activity} openDialog={openCopyDialog} setOpenDialog={setOpenCopyDialog} />
        </React.Fragment>
    )
}