import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Rating, Box, Typography } from '@mui/material';

const GET_RATINGS = gql`
    query GetRatings($activityId: UUID!) {
        allOcgTComments(
            condition: { activityId: $activityId, parentCommentId: null }
            orderBy: CREATED_AT_ASC
        ) { nodes { rating }}
    }
`;

export default function AverageRating({ activityId, showText = false }) {
    const { loading, data, error } = useQuery(GET_RATINGS, {
        variables: { activityId },
        fetchPolicy: 'network-only'
    });

    if (loading || error)
        return (null);

    const ratings =
        data?.allOcgTComments?.nodes.map((comment) => comment.rating) || [];

    if (ratings.length === 0) {
        return (null);
    }

    const average =
        ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Rating value={average} precision={0.1} readOnly />
        { showText === true ? (
        <Typography variant="body2" sx={{ ml: 1 }}>
            {average.toFixed(1)} / 5 ({ratings.length} ratings)
        </Typography>
        ) : null}
        </Box>
    );
}
